<template>
    <v-container class="wizard__content--item-container">
        <a-form :submit="submit">
            <stepper-row>
                <template #input>
                    <v-row>
                        <v-col md="6">
                            <a-select-input
                                v-model="requestData.category_id"
                                rules="required"
                                label="Category"
                                :items="categories"
                                item-text="name"
                                item-value="id"
                                placeholder="Select a category"
                                :disabled="loading"
                            />
                        </v-col>
                    </v-row>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <v-switch
                        v-model="isRecurring"
                        label="Generate Recurring Amps for this request"
                    />

                    <author-request-frequency-selector
                        v-if="requestData.frequency_in_days"
                        v-model="requestData.frequency_in_days"
                        :custom-frequency-enabled="canSelectCustomFrequency"
                    />
                </template>

                <template #info>
                    <a-alert
                        v-show="isRecurring"
                        type="info"
                        data-alert="alert-first"
                        data-type="info"
                    >
                        <p class="mb-0">
                            <b>
                                With a recurring campaign, we'll distribute one
                                fresh Amp every
                                {{ requestData.frequency_in_days }} days.
                            </b>

                            Recurring campaigns will deduct
                            <b>One DFY credit</b>
                            from your account every time we write a new Amp for
                            you.
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-row>
                <template #input>
                    <strong>Include distribution to authority sites</strong>

                    <v-checkbox
                        v-model="requestData.is_premium"
                        label="Premium Distribution"
                        hide-details
                        @change="$emit('premiumChange')"
                    />

                    <v-checkbox
                        v-model="requestData.is_financial"
                        label="Financial Distribution"
                        hide-details
                        class="mt-2"
                    />

                    <v-checkbox
                        v-model="requestData.is_msn"
                        label="Microsoft MSN Distribution"
                        hide-details
                        class="mt-2"
                    />
                </template>

                <template #info>
                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Premium Distribution adds publishing on
                            world-renowned news brands Business Insider, Medium,
                            and others.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ premiumCredits || 'no' }} Premium
                                Distribution Credits
                            </b>
                        </p>
                    </a-alert>

                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Financial Distribution adds publishing on Yahoo
                            Finance and others.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ financialCredits || 'no' }} Financial
                                Distribution Credits
                            </b>
                        </p>
                    </a-alert>

                    <a-alert type="info" data-type="info">
                        <p class="mb-0">
                            Microsoft MSN Distribution adds publishing on MSN.
                        </p>

                        <p class="mb-0">
                            <b>
                                You have
                                {{ msnCredits }} Microsoft MSN Distribution
                                Credits
                            </b>
                        </p>
                    </a-alert>
                </template>
            </stepper-row>

            <stepper-actions
                :next-button-text="nextButtonText"
                :show-next-arrow-icon="false"
                :disabled="checkingPayment"
                @prev="$emit('prev')"
            >
                <template #info>
                    <a-alert
                        data-type="info"
                        :type="credits ? 'success' : 'warning'"
                        :message="dfyCreditsInfo"
                    />
                    <a-alert
                        v-if="
                            purchaseCreditsNecessaryToContinue ||
                            checkingPayment
                        "
                        class="mt-8 mb-0"
                        data-type="info"
                        type="warning"
                        :message="creditsInfo"
                    />
                </template>
            </stepper-actions>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { InjectReactive, Watch } from '@/utils/decorators';

import { InfoFieldMixin } from '@/mixins';

import { AAlert } from '@/components/AAlert';
import { AForm } from '@/components/AForm';
import { StepperActions, StepperRow } from '@/components/Stepper';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { AuthorRequestFrequencySelector } from '@/components/Authoring/AuthorRequestFrequencySelector';

import type { Category } from '@/types/Category';

import type { AuthorRequestData } from '@/types/AuthorRequest';

const FinishingUpProps = Vue.extend({
    props: {
        categories: {
            type: Array as PropType<Category[]>,
            default() {
                return [];
            }
        },
        credits: {
            type: Number,
            default() {
                return 0;
            }
        },
        premiumCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        financialCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        msnCredits: {
            type: Number,
            default() {
                return 0;
            }
        },
        checkingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isActive: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        AAlert,
        StepperActions,
        StepperRow,
        ASelectInput,
        AuthorRequestFrequencySelector
    }
})
export default class FinishingUp extends mixins(
    FinishingUpProps,
    InfoFieldMixin
) {
    @InjectReactive({
        from: 'requestData',
        default() {
            return {};
        }
    })
    requestData!: AuthorRequestData;

    isRecurring: boolean | null = null;

    @Watch('isRecurring')
    onIsRecurringChange(isRecurring: boolean) {
        if (!isRecurring) {
            this.requestData.frequency_in_days = null;
        } else {
            this.requestData.frequency_in_days = 31;
        }

        this.onRender();
    }

    @Watch('isActive')
    onActivate() {
        this.onRender();
    }

    get loading() {
        return this.$store.state.loading.show;
    }

    get nextButtonText() {
        if (this.checkingPayment) {
            return 'Processing Payment...';
        }

        return this.purchaseCreditsNecessaryToContinue
            ? 'Checkout'
            : this.$vuetify.breakpoint.xsOnly
              ? 'Submit'
              : 'Submit using Authoring Credits';
    }

    get purchaseCreditsNecessaryToContinue() {
        return (
            !this.credits ||
            (this.requestData.is_premium && !this.premiumCredits) ||
            (this.requestData.is_financial && !this.financialCredits) ||
            (this.requestData.is_msn && !this.msnCredits)
        );
    }

    get creditsInfo() {
        if (this.checkingPayment) {
            return '<b>This page will update automatically once payment has been received.</b>';
        }

        if (this.purchaseCreditsNecessaryToContinue) {
            return '<b>You will be prompted to purchase credits before submission</b>';
        }

        return '';
    }

    get dfyCreditsInfo() {
        if (this.credits) {
            return `<b>You currently have ${this.credits} DFY credits.</b> Requesting a professionally-written Amp will deduct <b>1 DFY credit</b> from your account.`;
        }

        return '<b>You currently have no DFY credits.</b> You will have to purchase an DFY credit to submit this request.';
    }

    get canSelectCustomFrequency() {
        return this.credits > 0;
    }

    get ampCountFrequency() {
        return [2, 3, 4, 5, 6];
    }

    onRender() {
        this.$nextTick(() => {
            this.fitInfosFor('first');
        });
    }

    submit() {
        this.$emit('next');
    }

    mounted() {
        this.requestData.frequency = null;
        this.onRender();
    }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .gap {
        height: 20px;
    }
    .v-size--x-large {
        padding: 5px !important;
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
    .gap {
        height: 30px;
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
    .gap {
        height: 0px;
    }
}

.occurence-options {
    .v-radio--is-disabled {
        opacity: 0.5;
    }
}
</style>
